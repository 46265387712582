import React, { useState } from "react"
import { Container, Row } from "react-bootstrap"
import Seo from "../components/seo"
import SideBar from "../components/sideBar/SideBar"
import ProvHeroSection from "../components/programs/program"
import ModalProgram from "../components/programs/modalReg"
import ProviderNav from "../components/homeNav/ProviderNav"
import FaqSection from "../sections/faq/Faqprogram"
import Footer from "../components/homeNav/footer/Footer"
import styled from 'styled-components';


export default function ProgramIndex() {
  const [isOpen, setIsOpen] = useState(false)
  const [program, setProgram] = useState('')
  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (program) => {
    setIsModalOpen(true);
    setProgram(program);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };



  return (
    <Container fluid style={{ overflow: "hidden" }}>
      <Seo
        title="Empowering Your Health | Health programs offered at Afyabook"
        description="Our healthcare providers are dedicated to providing comprehensive health programs tailored to your needs. Explore our offerings at Afyabook"
        img="https://www.afyabook.com/static/8f4339bfc7163c1851f593c247d64895/39112/knowledge-thumb.webp"
        keywords="weight management programs in kenya, how to loose weight, Medication Therapy Management (MTM) kenya, Chronic Disease Management kenya, Immunization Services kenya, vaccination services kenya, health programs in kenya, Smoking Cessation Programs kenya, Health Screenings, patient education kenya, mental health support kenya, nutritional support kenya"
        siteUrl="https://www.afyabook.com/programs"
        canonical="https://www.afyabook.com/programs"
      ></Seo>
      <Row>
        <SideBar isOpen={isOpen} toggle={toggle} />
        <ProviderNav toggle={toggle} />
        <ProvHeroSection />
        <Header>
        <Subtitle>Our providers are dedicated to providing comprehensive health programs tailored to your needs</Subtitle>
        <Subtitle2>Explore our offerings below and take the first step towards better health!</Subtitle2>
      </Header>
      <Sections>
        {programsData.map((program, index) => (
          <ProgramSection key={index} reverse={index % 2 === 1}>
            <Image src={program.image} alt={program.title} />
            <Content>
              <SectionTitle>{program.title}</SectionTitle>
              <SectionContent>{program.content}</SectionContent>
              <SectionContent>{program.content1}</SectionContent>
              <SectionContent>{program.content2}</SectionContent>
              {program.ctaType === 'link' ? (
                <CTAButton href={program.ctaLink}>{program.ctaText}</CTAButton>
              ) : (
                <CTAButton onClick={()=>handleOpenModal(program)}>{program.ctaText}</CTAButton>
              )}

            </Content>
          </ProgramSection>
        ))}
      </Sections>
      {/* <ModalProgram isOpen={isModalOpen} handleClose={handleCloseModal} /> */}
        <FaqSection />
        <Footer />
        <ModalProgram isOpen={isModalOpen} onClose={handleCloseModal} program={program}/>

      </Row>
    </Container>
  )
}

const programsData = [
  {
    title: "Medication Therapy Management",
    content: "Are you taking multiple medications? Our MTM service ensures that your medications work together effectively and safely.",
    content1: "We analyze your complete medication regimen, identifying any potential drug interactions, side effects, or unnecessary medications.",
    content2: "This personalized approach not only optimizes your therapy but can also lead to improved health outcomes and reduced healthcare costs.",
    ctaText: "Learn More About MTM",
    ctaType: 'link',
    ctaLink: "/medication-therapy-management",
    image: "https://res.cloudinary.com/tripleaim-software/image/upload/v1727258111/prescriptions/klrjytcdhf4s9fufh8kb.jpg",
  },
  {
    title: "Exclusive Health Advantage Plan",
    modalDesc: "Unlock Unlimited Care with Our Exclusive Plan – Just KSh 6,000 a Year!",
    content: "Say goodbye to healthcare worries! Enjoy unlimited doctor consultations, plus a 5% discount on lab tests and medications, all year round.",
    content1: "With our affordable Advantage Package, staying healthy has never been this easy or budget-friendly!",
    content2: "Just KSh 6,000 a Year!",
    ctaText: "Join the Advantage Plan now!",
    ctaType: 'modal',
    //ctaLink: "#",
    image: "https://res.cloudinary.com/tripleaim-software/image/upload/v1728030704/afyabook%20images/family_aucp0d.png",
  },
  {
    title: "Chronic Disease Management",
    modalDesc: "Empower youself with the knowledge and tools you need to manage your condition",
    content: "Our specialized programs are designed to empower you with the knowledge and tools needed to take control of your health. Be it diabetes, hypertension, or asthma, we've got you covered.",
    content1: "From regular monitoring to personalized action plans, we work alongside you to set achievable health goals.",
    content2: "You'll receive online tools, ongoing support, educational resources, and lifestyle tips tailored to your condition, ensuring you feel confident and informed every step of the way.",
    ctaText: "Explore Chronic Disease Programs",
    ctaType: 'modal',
    // ctaLink: "../components/programs/mtm",
    image: "https://res.cloudinary.com/tripleaim-software/image/upload/v1727427473/afyabook%20images/chronic_1_pfq5m8.png",
  },
  {
    title: "Immunization Services",
    modalDesc: "Protect yourself and your loved ones with our convenient immunization services.",
    content: "Protect yourself and your loved ones with our convenient immunization services.",
    content1: "Our providers are trained to administer a wide range of vaccines, provide information, address concerns and help you make desicions about your health",
    content2: "Vaccines offered include: Measles, Influenza, Meningitis, Pneumonia, Cholera, Hepatitis, Typhoid, Tetanus.",
    ctaText: "Schedule Your Vaccine",
    ctaType: 'modal',
    // ctaLink: "#",
    image: "https://res.cloudinary.com/tripleaim-software/image/upload/v1727429602/afyabook%20images/vaccination_1_ukivcy.png",
  },
  {
    title: "Smoking Cessation Programs",
    modalDesc: "Ready to quit smoking?",
    content: "Ready to quit smoking? Our tailored cessation programs are designed to support you through every stage of your journey.",
    content1: "Will work with you to create a personalized quit plan, providing strategies, resources, and emotional support.",
    content2: "You’ll have access to various cessation aids, from nicotine replacement therapies to counseling, all aimed at increasing your chances of success. Together, we can help you reclaim your health!",
    ctaText: "Join Our Smoking Cessation Program",
    ctaType: 'modal',
    // ctaLink: "#",
    image: "https://res.cloudinary.com/tripleaim-software/image/upload/v1727428580/afyabook%20images/smoking_1_nelrbs.png",
  },
  {
    title: "Weight Management Programs",
    modalDesc: "Achieving and maintaining a healthy weight is a vital aspect of your overall health.",
    content: "Achieving and maintaining a healthy weight is a vital aspect of your overall health.",
    content1: "Offer individualized weight management programs that include nutritional counseling, exercise recommendations, appropriate medication, and goal-setting strategies",
    content2: "By focusing on sustainable lifestyle changes rather than quick fixes, we help you develop a healthier relationship with food and fitness, leading to lasting results",
    ctaText: "Start Your Weight Management Journey",
    ctaType: 'modal',
   // ctaLink: "#",
    image: "https://res.cloudinary.com/tripleaim-software/image/upload/v1727428078/afyabook%20images/weight_loss_1_tbqjcx.png",
  },
  {
    title: "Patient Education",
    modalDesc: "We offer educational resources on medication management, disease prevention, and healthy lifestyle choices.",
    content: "Knowledge is power. Our providers are here to empower you with the information you need to make informed health decisions",
    content1: "We offer educational resources on medication management, disease prevention, and healthy lifestyle choices.",
    content2: "Whether it’s a one-on-one consultation or corporates or access to informative workshops, we ensure you have the tools to take charge of your health",
    ctaText: "Consult with Our Providers",
    ctaType: 'modal',
    //ctaLink: "#",
    image: "https://res.cloudinary.com/tripleaim-software/image/upload/v1727676670/afyabook%20images/education_1_pwdkgx.png",
  },
  {
    title: "Nutritional Counseling",
    modalDesc: "Healthy eating is essential for overall wellness.",
    content: "Healthy eating is essential for overall wellness.",
    content1: "Our nutritionists provide personalized nutritional counseling, offering practical tips and meal planning support tailored to your specific needs and health goals.",
    content2: "Whether you’re looking to manage a condition or simply improve your diet, we’ll help you make informed choices that fit your lifestyle.",
    ctaText: "Consult with Our Nutrition Expert",
    ctaType: 'modal',
    //ctaLink: "#",
    image: "https://res.cloudinary.com/tripleaim-software/image/upload/v1727427693/afyabook%20images/nutrition_1_fugqiy.png",
  },
  {
    title: "Mental Health Support",
    modalDesc: "Our mental health experts offer support for those dealing with stress, anxiety, and other mental health challenges",
    content: "Your mental health matters. Our mental health experts offer support for those dealing with stress, anxiety, and other mental health challenges",
    content1: "We provide resources, coping strategies, and referrals to mental health professionals to ensure you receive comprehensive care.",
    content2: "Our goal is to create a safe space where you feel heard and supported",
    ctaText: "Get Mental Health Resources",
    ctaType: 'modal',
   // ctaLink: "#",
    image: "https://res.cloudinary.com/tripleaim-software/image/upload/v1727420038/afyabook%20images/mental_health_k6lnhf.png",
  },
  {
    title: "Health Screenings",
    modalDesc: "Early detection is key to managing your health effectively.",
    content: "Early detection is key to managing your health effectively.",
    content1: "Our health screening services provide you with essential checks for blood pressure, cholesterol, blood glucose, and more.",
    content2: "After your screening, our providers will discuss your results in detail, offering actionable insights and recommendations to help you improve your health or manage any conditions",
    ctaText: "Book Your Screening Today",
    ctaType: 'modal',
    //ctaLink: "#",
    image: "https://res.cloudinary.com/tripleaim-software/image/upload/v1727429209/afyabook%20images/health_screening_1_ibu6vq.png",
  },
  {
    title: "Family Planning Services",
    modalDesc: "Empower Your Future: Discover Family Planning Solutions Today!",
    content: "Take control of your reproductive health with our comprehensive family planning services.",
    content1: "Our expert team is here to guide you through personalized options that suit your lifestyle.",
    content2: "Includes: Birth Control Pills, Hormonal Injections, Hormonal Implants, Copper IUD, Hormonal IUD, Fertility Awareness, Withdrawal, Emergency Contraceptive Pills",
    ctaText: "Ready to plan your future? ",
    ctaType: 'modal',
    //ctaLink: "#",
    image: "https://res.cloudinary.com/tripleaim-software/image/upload/v1727419507/afyabook%20images/pregnancy_awjem6.png",
  },
  {
    title: "Comprehensive Sexual Wellness Care",
    modalDesc: "Your Health, Your Choice: Sexual Wellness with No Stigma",
    content: "Take control of your sexual health with our discreet and professional services, including comprehensive screenings, expert STI treatments, Premature ejaculation, Erectile dysfunction(ED).",
    content1: "Your privacy and well-being are our top priorities, ensuring you receive personalized care in a judgment-free environment.",
    content2: "We're here to guide you every step of the way towards better health and peace of mind.",
    ctaText: "Schedule a Consultation",
    ctaType: 'modal',
    //ctaLink: "#",
    image: "https://res.cloudinary.com/tripleaim-software/image/upload/v1728031950/afyabook%20images/sexual-health_ssuwkd.png",
  },
  {
    title: "Maternal and Child Health (MCH)",
    modalDesc: "Because Every Mom and Child Deserves the Best Care",
    content: "Your journey to motherhood deserves personalized care and support. From prenatal checkups to child health screenings, we’re here to ensure the best outcomes for you and your baby",
    content1: "Motherhood can be challenging, but you don’t have to go through it alone. Let us help you every step of the way.",
    content2: "Book your appointment today and embrace a healthier pregnancy and brighter future for your child",
    ctaText: "Secure your family's future",
    ctaType: 'modal',
    //ctaLink: "#",
    image: "https://res.cloudinary.com/tripleaim-software/image/upload/v1728030047/afyabook%20images/mch_mjfrne.png",
  },
  {
    title: "Specialized Health Packages",
    modalDesc: "Take charge of your health with packages tailored to your unique journey!",
    content: "Wellness Packages👉Comprehensive health check-ups for both men and women, promoting overall wellness and early detection of potential issues. Available in basic, advanced, and premium.",
    content1: "Pre-Wedding Package👉Designed for couples, this package includes a mini health check-up and STI screening to ensure both partners are in good health before marriage. Available in basic, advanced, and premium.",
    content2: "Coming Baby Check-Up👉Focused on fertility, this package offers infertility testing and health assessments for couples planning to start a family. Available in basic, advanced, and premium.",
    ctaText: "Don’t wait, Invest in your health now!",
    ctaType: 'modal',
    //ctaLink: "#",
    image: "https://res.cloudinary.com/tripleaim-software/image/upload/v1728031493/afyabook%20images/packages_ypq9bc.png",
  },
  // {
  //   title: "What Our Patients Say",
  //   content: "Hear from our patients about their positive experiences with our programs",
  //   content1: "*****",
  //   content2: "This program has been a game-changer for me. I feel more confident in managing my health and making informed decisions. Thank you!",
  //   ctaText: "Testimonials",
  //   ctaType: 'link',
  //   ctaLink: "#",
  //   image: "https://res.cloudinary.com/tripleaim-software/image/upload/v1727258111/prescriptions/klrjytcdhf4s9fufh8kb.jpg",
  // },
  
];

const Header = styled.header`
  text-align: center;
  //margin-bottom: 40px 0;
`;

const Title = styled.h1`
  font-size: 2.5em;
  margin: 0;
`;

const Subtitle = styled.h2`
  font-size: 1.5em;
  margin-top: 50px;
`;
const Subtitle2 = styled.h2`
  font-size: 1.5em;
  margin-top: 10px;
`;

const CTAButton = styled.a`
  display: inline-block;
  padding: 10px 20px;
  background-color: #1d9682;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
`;

const Sections = styled.div`
  margin: 20px 0;
`;

const ProgramSection = styled.div`
  display: flex;
  align-items: center;
  margin: 100px 0;
  justify-content: space-evenly;
  flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Image = styled.img`
  width: 40%;
  border-radius: 10px;
  margin: 0 20px;
  max-width: 400px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

const Content = styled.div`
  flex: 1;
  padding: 20px;
  max-width: 400px;
`;

const SectionTitle = styled.h3`
  font-size: 2em;
  margin: 0 0 10px;
`;

const SectionContent = styled.p`
  font-size: 1.2em;
  margin: 0 0 20px;
`;



